/*!
 * Theme Name: NJIE
 * Description: NJIE
 * Author: Arash Ohadi
 * Company: Republiken
 * Website: https://republiken.se
*/

@import "shared/typography.pcss";

/* Variables, colors, mixins */
@import "mixins/link.pcss";
@import "mixins/shadow.pcss";
@import "mixins/transition.pcss";

/* Components */
@import "@splidejs/splide/dist/css/splide-core.min.css";
@import "components/brand.pcss";
@import "components/body.pcss";
@import "components/button.pcss";
@import "components/blockquote.pcss";
@import "components/column.pcss";
@import "components/copyright.pcss";
@import "components/cookies.pcss";
@import "components/expander.pcss";
@import "components/filter.pcss";
@import "components/footer.pcss";
@import "components/form.pcss";
@import "components/grid.pcss";
@import "components/hr.pcss";
@import "components/list.pcss";
@import "components/product.pcss";
@import "components/scrollbar.pcss";
@import "components/selection.pcss";
@import "components/slider.pcss";
@import "components/tabs.pcss";

/* Tailwind Utilities */
@import "tailwindcss/utilities";
